// extracted by mini-css-extract-plugin
export var landingPageHero = "hero-module--landingPageHero--3AcTm";
export var heroIntro = "hero-module--heroIntro--IrjzT";
export var text2 = "hero-module--text2--2QXY9";
export var text4 = "hero-module--text4--1NxMu";
export var neonGlow = "hero-module--neonGlow--1DF3r";
export var text3 = "hero-module--text3--1JHbI";
export var cursor = "hero-module--cursor--2HYsE";
export var heroBtns = "hero-module--hero-btns--1fHu-";
export var scrollMarkWrapper = "hero-module--scroll-mark-wrapper--1J8Ep";
export var arrow = "hero-module--arrow--39-p1";
export var move = "hero-module--move--3gW7T";
export var text1 = "hero-module--text1--21YpR";
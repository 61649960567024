// extracted by mini-css-extract-plugin
export var about = "about-module--about--2L5js";
export var aboutMeFacts = "about-module--about-me-facts--3wjcJ";
export var facts = "about-module--facts--S5zYx";
export var aboutButtonWrapper = "about-module--about-button-wrapper--2wb1a";
export var aboutMeText = "about-module--about-me-text--OQ7c1";
export var ctaSection = "about-module--cta-section--qcqPJ";
export var aboutMeQuestion = "about-module--about-me-question--YSYKC";
export var aboutMeText2 = "about-module--about-me-text-2--36ddU";
export var remove = "about-module--remove--3YBNE";
export var aboutMeWrapper = "about-module--about-me-wrapper--24ap1";
export var aboutButton = "about-module--about-button--iOJ8d";